export default [
    // {
    //     title: 'Dashboards',
    //     icon: 'HomeIcon',
    //     tag: '2',
    //     tagVariant: 'light-warning',
    //     children: [
    //       {
    //         title: 'eCommerce',
    //         route: 'dashboard-ecommerce',
    //       },
    //       {
    //         title: 'Analytics',
    //         route: 'dashboard-analytics',
    //       },
    //     ],
    //   },
    {
      title: 'Dashboards',
      route: 'dashboard-analytics',
      icon: 'HomeIcon',
    },
    {
        title: 'Pengaduan',
        route: 'pengaduan',
        icon: 'MessageSquareIcon',
    },
    {
      title: 'Profil',
      route: 'apps-profil-view',
      icon: 'UserIcon',
    },
  ]
  
export default [
    // {
    //     title: 'Dashboards',
    //     icon: 'HomeIcon',
    //     tag: '2',
    //     tagVariant: 'light-warning',
    //     children: [
    //       {
    //         title: 'eCommerce',
    //         route: 'dashboard-ecommerce',
    //       },
    //       {
    //         title: 'Analytics',
    //         route: 'dashboard-analytics',
    //       },
    //     ],
    //   },
    {
      title: 'Dashboards',
      route: 'dashboard-analytics',
      icon: 'HomeIcon',
    },
    {
      title: 'Whatsapp',
      route: 'wa-chat',
      icon: 'PhoneIcon',
    },
    {
        title: 'Telegram',
        route: 'telegram-chat',
        icon: 'SendIcon',
    },
    {
        title: 'Email',
        route: 'apps-email',
        icon: 'MailIcon',
    },
    {
        title: 'All-Message',
        route: 'all-message',
        icon: 'MessageSquareIcon',
    },
    {
        title: 'Mapping',
        route: 'mapping',
        icon: 'MapIcon',
    },
    {
        title: 'Cluster',
        route: 'cluster',
        icon: 'MessageSquareIcon',
    },
    {
      title: 'Profil',
      route: 'apps-profil-view',
      icon: 'UserIcon',
    },
  ]
  
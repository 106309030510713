export default [
    // {
    //     title: 'Dashboards',
    //     icon: 'HomeIcon',
    //     tag: '2',
    //     tagVariant: 'light-warning',
    //     children: [
    //       {
    //         title: 'eCommerce',
    //         route: 'dashboard-ecommerce',
    //       },
    //       {
    //         title: 'Analytics',
    //         route: 'dashboard-analytics',
    //       },
    //     ],
    //   },
    // {
    //   header: 'Apps & Pages',
    // },
    {
      title: 'Dashboards',
      route: 'dashboard-analytics',
      icon: 'HomeIcon',
    },
    {
      title: 'Whatsapp',
      route: 'wa-chat',
      icon: 'PhoneIcon',
    },
    {
        title: 'Telegram',
        route: 'telegram-chat',
        icon: 'SendIcon',
    },
    {
        title: 'Email',
        route: 'apps-email',
        icon: 'MailIcon',
    },
    {
        title: 'All-Message',
        route: 'all-message',
        icon: 'MessageSquareIcon',
    },
    // {
    //     title: 'Report',
    //     icon: 'PieChartIcon',
    //     tag: '3',
    //     tagVariant: 'light-danger',
    //     children: [
    //       {
    //         title: 'Apex Chart',
    //         route: 'charts-apex-chart',
    //       },
    //       {
    //         title: 'Chartjs',
    //         route: 'charts-chartjs',
    //       },
    //       {
    //         title: 'Echart',
    //         route: 'charts-echart',
    //       },
    //     ],
    // },
    {
        title: 'User',
        icon: 'UserIcon',
        children: [
          {
            title: 'List',
            route: 'apps-users-list',
          },
          {
            title: 'Hak Akses',
            route: 'apps-users-hak-akses',
          },
          // {
          //   title: 'View',
          //   route: { name: 'apps-users-view', params: { id: 21 } },
          // },
          // {
          //   title: 'Edit',
          //   route: { name: 'apps-users-edit', params: { id: 21 } },
          // },
        ],
    },
    {
      title: 'Profil',
      route: 'apps-profil-view',
      icon: 'UserIcon',
    },
  ]
  